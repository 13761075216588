import TracksPlayer from './tracks';

export default function PageSongs() {
  $('.sorts a').click(function(e){
    e.preventDefault();

    if ($(this).hasClass('link-anchor')) {
      var pos = $($(this).attr('href')).offset().top;

      $('html, body').animate({
        scrollTop: pos
      }, 800);
    } else {
      $('.sorts a').not(this).removeClass('active');
      $(this).toggleClass('active');
      $('#search').val('');

      do_filter();
    }    
  });

  $('#search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      $('.sorts a').removeClass('active');
      do_filter();
    }
  });

  $('.track-table thead .sortable').click(function(e){
    do_filter();
  });

  function do_filter() {
    var filter_url = $('.sorts').data('url');
    var params = [];

    if ($('.sorts a.active').length > 0) {
      params.push('starts_with=' + $('.sorts a.active').data('value'));
    }

    if ( $('#search').val() != '' ) {
      params.push( 'xmas-search=' + $('#search').val() );
    }

    if ($('.track-table thead .sortable.active').length > 0) {
      var sort = $('.track-table thead .sortable.active');
      params.push( 'sort_name=' + $(sort).data('name') );
      params.push( 'sort_value=' + $(sort).data('value') );
    }

    $('.songs').append('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.legacy-streaming-audio-player').remove();
        $('.wp-pagenavi').remove();

        $('.songs').append( $(res).find('.legacy-streaming-audio-player') );
        $('.songs').append( $(res).find('.wp-pagenavi') );
        $('.songs .track-table tbody').html( $(res).find('.songs .track-table tbody').html() );
        $('.songs .loading').remove();
        $('.legacy-streaming-audio-player').streamingAudioPlayer();
      }
    });
  }

  $(document).on('click', '.wp-pagenavi a', function(e){
    e.preventDefault();

    $('.songs').append('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.legacy-streaming-audio-player').remove();
        $('.wp-pagenavi').remove();

        $('.songs').append( $(res).find('.legacy-streaming-audio-player') );
        $('.songs').append( $(res).find('.wp-pagenavi') );
        $('.songs .track-table tbody').html( $(res).find('.songs .track-table tbody').html() );
        $('.songs .loading').remove();
        $('.legacy-streaming-audio-player').streamingAudioPlayer();
      }
    });
  });
}