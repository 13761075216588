export default function SingleArtist() {
  $(document).on('click', '.artist-playlists .btn-loadmore', function(){
    $(this).addClass('loading');
    var url = $(this).data('url');
    $.ajax({
      url: url,
      type: 'GET',
      success: function(res) {
        $('.artist-playlists .playlists').append($(res).find('.artist-playlists .playlists').html());
        $('.artist-playlists .btn-contr').remove();
        $('.artist-playlists .playlists').after($(res).find('.artist-playlists .btn-contr'));
      }
    });
  });

  $(document).on('click', '.artist-albums .btn-loadmore', function(){
    $(this).addClass('loading');
    var url = $(this).data('url');
    $.ajax({
      url: url,
      type: 'GET',
      success: function(res) {
        $('.artist-albums .albums').append($(res).find('.artist-albums .albums').html());
        $('.artist-albums .btn-contr').remove();
        $('.artist-albums .albums').after($(res).find('.artist-albums .btn-contr'));
      }
    });
  });

  $(document).on('click', '.artist-songs .btn-loadmore', function(){
    $(this).addClass('loading');
    var url = $(this).data('url');
    $.ajax({
      url: url,
      type: 'GET',
      success: function(res) {
        $('.artist-songs .track-table-wrapper tbody').append($(res).find('.artist-songs .track-table-wrapper tbody').html());
        $('.artist-songs .btn-contr').remove();
        $('.artist-songs .container').append($(res).find('.artist-songs .legacy-streaming-audio-player'));
        $('.artist-songs .container').append($(res).find('.artist-songs .btn-contr'));
        var players = $('.legacy-streaming-audio-player');
        $(players[players.length - 1]).streamingAudioPlayer();
      }
    });
  });
}