export default function customCarousels() {
    $('.countdown .slider__cards').owlCarousel({
        items: 1,
        dotsEach: false,
        loop: true,
        nav: false,
        navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
        margin: 0,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                margin: 10,
            },
            480: {
                margin: 80,
            },
            768: {
                margin: 80,
            },
            1440: {
                margin: 128,
            },
        },
    });

    $('.lyric .slider__cards').owlCarousel({
        items: 1,
        dotsEach: false,
        loop: true,
        nav: false,
        navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
        margin: 0,
        dots: false,
        responsive: {
            0: {
                margin: 40,
            },
            480: {
                margin: 60,
            },
            768: {
                margin: 100,
            },
            1024: {
                margin: 150,
            },
            1440: {
                margin: 300,
            },
        },
    });

    $('.new-christmas__slider').owlCarousel({
        items: 5,
        dotsEach: false,
        loop: true,
        nav: false,
        navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            480: {
                items: 2,
                margin: 10,
            },
            768: {
                items: 3,
                margin: 10,
            },
            1280: {
                items: 5,
                margin: 30,
            },
        },
    });

    $('.feature .posts__content').owlCarousel({
        items: 3,
        dotsEach: false,
        loop: true,
        nav: false,
        navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            480: {
                items: 2,
                margin: 10,
            },
            1024: {
                items: 3,
                margin: 20,
            },
        },
    });

    $('.r-musics-slider').owlCarousel({
        items: 6,
        dotsEach: false,
        loop: true,
        nav: false,
        navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            480: {
                items: 2,
                margin: 10,
            },
            768: {
                items: 4,
                margin: 10,
            },
            1280: {
                items: 5,
                margin: 15,
            },
            1400: {
                items: 6,
                margin: 15,
            },
        },
    });

    if ($('.artist-videos').length > 0) {
        $('.artist-videos').each(function() {
            const owl = $(this).find('.videos-slider').owlCarousel({
                items: 1,
                dotsEach: false,
                loop: true,
                nav: false,
                navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
                margin: 0,
                dots: false,
                responsive: {
                    0: {
                        margin: 10
                    },
                    480: {
                        margin: 80,
                    },
                    768: {
                        margin: 80,
                    },
                    1440: {
                        margin: 128,
                    },
                },
            });
            $(this).find('.artist-videos-slider-prev').on('click', function(e) {
                owl.trigger('prev.owl.carousel');
                e.preventDefault();
            })
            $(this).find('.artist-videos-slider-next').on('click', function(e) {
                owl.trigger('next.owl.carousel');
                e.preventDefault();
            })
        })
    }
    
}
