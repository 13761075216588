export default function PageVideos() {
  $('.filters .custom-dropdown li').click(function(e){
    e.preventDefault();
    $(this).parent().parent().data('value', $(this).data('value'));
    do_filter();
  });

  $('.sorts a').click(function(e){
    e.preventDefault();
    $('.sorts a').not(this).removeClass('active');
    $(this).addClass('active');

    $('.sorts').data('name', $(this).data('name'));
    $('.sorts').data('value', $(this).data('value'));
    do_filter();
  });

  $('#search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      do_filter();
    }
  });

  function do_filter() {
    var filter_url = $('.filters').data('url');
    var params = [];

    $('.filters .custom-dropdown').each(function(){
      if ($(this).data('value') && $(this).data('value') != '') {
        params.push($(this).data('name') + '=' + $(this).data('value'));
      }
    });

    if ($('.sorts').data('value') && $('.sorts').data('value') != '') {
      params.push('sort_name=' + $('.sorts').data('name'));
      params.push('sort_value=' + $('.sorts').data('value'));
    }

    if ( $('#search').val() != '' ) {
      params.push( 'xmas-search=' + $('#search').val() );
    }

    $('.wp-pagenavi').remove();
    $('.videos').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.videos').html( $(res).find('.videos').html() );
        $('.videos').after( $(res).find('.wp-pagenavi') );
      }
    });
  }

  $(document).on('click', '.wp-pagenavi a', function(e){
    e.preventDefault();

    $('.wp-pagenavi').remove();
    $('.videos').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.videos').html( $(res).find('.videos').html() );
        $('.videos').after( $(res).find('.wp-pagenavi') );
      }
    });
  });

  $(document).on('click', '.videos .card__img--video', function(e){
    e.preventDefault();

    $.magnificPopup.open({
      items: {
        src: $(this).data('popup'),
        type: 'inline'
      }
    });
  });

  $(document).on('click', '.popup-video .btn-play', function(e){
    e.preventDefault();
    var iframe = $(this).parent().find('iframe');
    iframe.attr('src', $(iframe).data('url'));
    $(this).parent().find('img').hide();
  });
}