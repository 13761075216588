
export default function nav() {
  $('.js-menu-toggle').on('click', function (e) {
    $('.header__content').addClass('active');
    $('#overlay').addClass('active');
  });

  $('.js-menu-close').on('click', function (e) {
    $('.header__content').removeClass('active');
    $('#overlay').removeClass('active');
  });

  $('.js-button-language').on('click', function (e) {
    $(this).parent().toggleClass('show');
  });

  $('.share-bar > a').click(function(e){
    e.preventDefault();

    $(this).find('i').toggleClass('fa-share-alt');
    $(this).find('i').toggleClass('fa-times');

    $('.share-bar').toggleClass('open');
  });

  // Social popup open
	$('.share-bar ul li a').click(function(e){
		e.preventDefault();
		const link = $(this).attr("href");
		const w = 670;
		const h = 378;
		const y = window.outerHeight / 2 + window.screenY - (h / 2)
		const x = window.outerWidth / 2 + window.screenX - (w / 2)
		window.open(link, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
	});

  if ($('body').hasClass('single-youtube_playlist') || $('body').hasClass('single-playlist')) {
    $('.link-playlists').addClass('current-menu-item');
  } else if ($('body').hasClass('single-sby_videos')) {
    $('.link-videos').addClass('current-menu-item');
  } else if ($('body').hasClass('single-album_track') || $('body').hasClass('single-playlist_track')) {
    $('.link-songs').addClass('current-menu-item');
  } else if ($('body').hasClass('single-artist')) {
    $('.link-artists').addClass('current-menu-item');
  }
  
}