import 'owl.carousel';
import 'magnific-popup';
require('readmore-js');

import nav from './modules/nav';
import customCarousels from './modules/carousels';
import popup from './modules/popup';
import video from './modules/video';
import customDropdown from './modules/custom-dropdown';
import PageVideos from './pages/videos';
import PagePlaylists from './pages/playlists';
import PageArtists from './pages/artists';
import PageSongs from './pages/songs';
import TracksPlayer from './pages/tracks';
import VideoSlider from './pages/video-slider';
import SingleArtist from './pages/single-artist';
import SingleAlbum from './pages/single-album';
import SingleTrack from './pages/single-track';

jQuery( document ).ready(function($) {
  nav();
  customCarousels();
  popup();
  video();
  customDropdown();
  TracksPlayer();
  VideoSlider();

  if ($('body').hasClass('page-template-videos')) {
    PageVideos();
  }
  
  if ($('body').hasClass('page-template-playlists')) {
    PagePlaylists();
  }

  if ($('body').hasClass('page-template-artists')) {
    PageArtists();
  }

  if ($('body').hasClass('single-artist')) {
    SingleArtist();
  }

  if ($('body').hasClass('single-album')) {
    SingleAlbum();
  }

  if ($('body').hasClass('single-album_track') || $('body').hasClass('single-playlist_track')) {
    SingleTrack();
  }

  if ($('body').hasClass('page-template-songs')) {
    PageSongs();
  }
});
