export default function VideoSlider() {
  var slider;
  slider = $('.video-slider-wrapper .video-slider').owlCarousel({
    items: 1,
    dotsEach: false,
    loop: true,
    nav: false,
    navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
    margin: 40,
    dots: false,
    center: true,
    responsive: {
      768: {
        items: 2,
        margin: 80,
      },
      992: {
        items: 2,
        margin: 120,
      },
    },
    onTranslated: function(){
      $('.video-slider-wrapper .video-slider .video-wrapper').each(function(){
        $(this).find('iframe').attr('src', '');
        $(this).find('img').show();
      });
    }
  });

  $('.video-slider-wrapper .video-slider .item .btn-play').click(function(e){
    e.preventDefault();
    var iframe = $(this).parent().find('iframe');
    iframe.attr('src', $(iframe).data('url'));
    $(this).parent().find('img').hide();
  });

  $('.video-slider-wrapper .video-slider-nav a.slider-prev').click(function(e){
    e.preventDefault();
    slider.trigger('prev.owl.carousel');
  });

  $('.video-slider-wrapper .video-slider-nav a.slider-next').click(function(e){
    e.preventDefault();
    slider.trigger('next.owl.carousel');
  });
}
