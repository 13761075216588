export default function PageArtists() {
  $('.sorts a').click(function(e){
    e.preventDefault();

    $('.sorts a').not(this).removeClass('active');
    $(this).toggleClass('active');
    $('#search').val('');

    do_filter();
  });

  $('#search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      $('.sorts a').removeClass('active');
      do_filter();
    }
  });

  function do_filter() {
    var filter_url = $('.sorts').data('url');
    var params = [];

    if ($('.sorts a.active').length > 0) {
      params.push('starts_with=' + $('.sorts a.active').data('value'));
    }

    if ( $('#search').val() != '' ) {
      params.push( 'xmas-search=' + $('#search').val() );
    }

    $('.wp-pagenavi').remove();
    $('.artists').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.artists').html( $(res).find('.artists').html() );
        $('.artists').after( $(res).find('.wp-pagenavi') );
      }
    });
  }

  $(document).on('click', '.wp-pagenavi a', function(e){
    e.preventDefault();

    $('.wp-pagenavi').remove();
    $('.artists').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.artists').html( $(res).find('.artists').html() );
        $('.artists').after( $(res).find('.wp-pagenavi') );
      }
    });
  });
}