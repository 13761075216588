export default function video() {
  $('.js-video-watching').on('click', function (e) {
    const video_url = $(this).data('video');
    const iframe = `<iframe src="${video_url}?autoplay=1&muted=1" frameborder="0" allowfullscreen></iframe>`;
    $(this).parents('.video__watching').addClass('playing').find('.video__iframe').html(iframe);
  });

  $('.xmas-js-video .btn-play').click(function(e) {
    e.preventDefault();
    var iframe = $(this).parent().find('iframe');
    iframe.attr('src', $(iframe).data('url'));
    $(this).parent().find('img').hide();
  });
}
