export default function TracksPlayer() {
  var current_playing_id;

  $(document).on('click', '.track-table-wrapper .btn-play-track', function(e){
    e.preventDefault();

    $('.track-table-wrapper .btn-play-track').not(this).each(function(){
      $(this).find('i').addClass('fa-play');
      $(this).find('i').removeClass('fa-pause');
      $(this).removeClass('active');
    });

    $(this).find('i').toggleClass('fa-play');
    $(this).find('i').toggleClass('fa-pause');
    $(this).toggleClass('active');

    var track_id = $(this).parent().parent().data('track-id');

    $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-controls-play-pause').click();

    if ($(this).find('i').hasClass('fa-pause')) {
      current_playing_id = track_id;
      setTimeout(function(){
        updateProgressBar(track_id);
      }, 50);

      var top = $(this).parent().parent().position().top;
			var popup = $('.track-table-wrapper .stream-popup');

      popup.css('top', top - popup.outerHeight() + 10).fadeIn();
      setTimeout( function () {
        popup.fadeOut();
      }, 8000);
    } else {
      current_playing_id = 0;
    }
  });

  function updateProgressBar(track_id) {
    if (current_playing_id == 0 || current_playing_id != track_id) return;

    var lsap_progressbar = $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-progress');
    var progressbar = $('.track-table-wrapper tr[data-track-id=' + track_id + '] .track-playing-progress');
    $(progressbar).width(lsap_progressbar.width());

    if (lsap_progressbar.width() >= $('.track-table-wrapper').width()) {
      $('.track-table-wrapper tr[data-track-id=' + track_id + '] .btn-play-track i').addClass('fa-play');
      $('.track-table-wrapper tr[data-track-id=' + track_id + '] .btn-play-track i').removeClass('fa-pause');
      lsap_progressbar.width(0);
      progressbar.width(0);
      $('.track-table-wrapper tr[data-track-id=' + track_id + '] .btn-play-track').removeClass('active');
    } else {
      setTimeout(function(){
        updateProgressBar(track_id);
      }, 50);
    }
  }

  $('.track-table-wrapper thead .sortable').click(function(e){
    $('.track-table-wrapper thead .sortable').not(this).each(function(){
      $(this).find('i').removeClass('fa-caret-up fa-caret-down');
      $(this).find('i').addClass('fa-sort');
    });
    $('.track-table-wrapper thead .sortable').removeClass('active');
    $(this).addClass('active');

    var icon = $(this).find('i');

    if (icon.hasClass('fa-caret-up')) {
      icon.removeClass('fa-sort fa-caret-up');
      icon.addClass('fa-caret-down');
      $(this).data('value', 'DESC');
    } else {
      icon.removeClass('fa-sort fa-caret-down');
      icon.addClass('fa-caret-up');
      $(this).data('value', 'ASC');
    }
  });

  $('.track-table-wrapper .stream-service-selection ul li button').click(function(e){
    e.preventDefault();
    $('.track-table-wrapper .stream-service-selection ul li button').removeClass('active');
    $(this).addClass('active');
    $('.lsap-stream-service-selection button[data-service=' + $(this).data('service') + ']').click();
  });
}