export default function SingleTrack() {
  $(document).on('click', '.related-musics .btn-loadmore', function(){
    $(this).addClass('loading');
    var url = $(this).data('url');
    $.ajax({
      url: url,
      type: 'GET',
      success: function(res) {
        $('.related-musics .related-musics-list').append($(res).find('.related-musics .related-musics-list').html());
        $('.related-musics .btn-contr').remove();
        $('.related-musics .related-musics-list').after($(res).find('.related-musics .btn-contr'));
      }
    });
  });
}